import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Map from '../assets/svg/map.svg';
import MapReachUs from '../components/mapreachus';

import Button from './button';

import Loader from '../assets/svg/loader.svg';

import { media, colors } from '../styles/constants';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${media.tabletPortrait}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > h2 {
    padding-bottom: 3rem;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    color: ${colors.blue};
  }

  ${Button} {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    svg {
      animation: ${rotate} 2s linear infinite;
    }
  }
`;

const Container = styled.div`
  > form {
    max-width: 40rem;

    label {
      display: flex;
      flex-direction: column;
      font-size: 1.125rem;
      color: ${colors.blue};

      padding-bottom: 1.5rem;
    }

    input,
    textarea {
      margin-top: 0.25rem;
      padding: 0.25rem;
      border-radius: 5px;
      border: solid 1px ${colors.blue};

      &:disabled {
        border: solid 1px ${colors.grey};
        background-color: ${colors.background};
      }
    }

    textarea {
      min-height: 6rem;
      resize: none;

      @media (min-width: ${media.tabletPortrait}px) {
        min-height: 10rem;
      }
    }
  }
`;

const ContactUs = styled.div`
  width: 100%;
`;

const ReachUs = styled.div`
  width: 100%;
  margin-top: 2rem;

  @media (min-width: ${media.tabletPortrait}px) {
    margin-top: 0;
    width: 80%;
  }

  > div {
    height: 50%;
    margin-bottom: 1rem;
    padding-top: 22px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 10rem;
`;

const Contacts = styled.address`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;

  @media (min-width: ${media.tabletPortrait}px) {
    padding-top: 2.5rem;
  }

  h3 {
    font-size: 2rem;
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    color: ${colors.blue};
    transition: color 150ms ease-in-out;

    &:focus,
    &:hover {
      color: ${colors.lightBlue};
    }

    svg {
      min-width: 2rem;
      min-height: 3rem;
      margin-right: 1rem;
    }

    p,
    span {
      font-size: 1.25rem;
    }
  }
`;

const ContactForm = ({ title = 'Contact Us', page = 'Home' }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mail
          gmaps
        }
      }
    }
  `);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (email.length > 0 && message.length > 0) {
      try {
        const response = await fetch('/.netlify/functions/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            page,
            email,
            message
          })
        });

        await response.json();
        setSubmitMessage(
          'Thank you for reaching us. We will come back to you shortly!'
        );
      } catch (err) {
        setSubmitMessage('Something went wrong. Please try again later.');
      }
    } else {
      alert('Please submit an email and message');
    }

    setLoading(false);
  };

  const areFieldsDisabled = isLoading || submitMessage.length > 0;

  return (
    <BackgroundContainer>
      <ContactUs>
        <h2>{title}</h2>
        <Container>
          <form onSubmit={handleSubmit}>
            <label>
              Business Email
              <input
                maxLength={100}
                disabled={areFieldsDisabled}
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
            </label>
            <label>
              Your Message
              <textarea
                maxLength={1000}
                disabled={areFieldsDisabled}
                value={message}
                onChange={handleMessageChange}
              />
            </label>
            {submitMessage.length > 0 ? (
              submitMessage
            ) : (
              <Button disabled={areFieldsDisabled} type="submit">
                {isLoading && <Loader />}
                {!isLoading && !areFieldsDisabled && 'Submit'}
              </Button>
            )}
          </form>
        </Container>
      </ContactUs>
      <ReachUs>
        <h2>Reach Us</h2>
        <div>
          <MapReachUs />
        </div>
        <Contacts>
          <a href={data.site.siteMetadata.gmaps} aria-label="Our Office">
            <Map />
            <Address>
              <p>UPTEC</p>
              <p>Rua Alfredo Allen, N.º455 3.16, 4200-135 </p>
              <p>Porto, Portugal</p>
            </Address>
          </a>
        </Contacts>
      </ReachUs>
    </BackgroundContainer>
  );
};

ContactForm.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string
};

export default ContactForm;

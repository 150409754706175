import React from 'react';
import styled from 'styled-components';
import { colors, media } from '../styles/constants';

const MapContainer = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
`;

const ContainerElement = styled.div`
  width: 100%;
  height: 25rem;

  @media (min-width: ${media.tabletLandscape}px) {
    height: 100%;
  }
`;

function MapReachUs() {
  return (
    <ContainerElement>
      <MapContainer>
        <iframe
          title="Map"
          src={`https://www.openstreetmap.org/export/embed.html?bbox=-8.606404,41.174813,-8.602404,41.178813&layer=mapnik&marker=41.176813,-8.604404`}
          style={{
            border: 0,
            width: '100%',
            height: '100%',
            maxWidth: '100%'
          }}
          allowFullScreen
        />
      </MapContainer>
    </ContainerElement>
  );
}

export default MapReachUs;
